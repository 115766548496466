function getAuthHost() {
    let retUrl;
    const currentURL = window.location.href;
    if (
        currentURL.includes('irisairmi.nike.com/implicit/callback') ||
        currentURL.includes('irisairmi.nike.com')
    ) {
        //PROD
        retUrl = `https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7/v1/authorize`;
    } else {
        //DEV
        retUrl = `https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7/v1/authorize`;
    }
    console.log("retUrl: " + retUrl);

    return `${retUrl}/`;
}
function getIssuerHost() {
    let issueUrl;
    const currentURL = window.location.href;
    if (
        currentURL.includes('irisairmi.nike.com/implicit/callback') ||
        currentURL.includes('irisairmi.nike.com')
    ) {
        //PROD
        issueUrl = `https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7`;
    } else {
        //DEV
        issueUrl = `https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7`;
    }
    console.log("issuer: " + issueUrl);

    return `${issueUrl}/`;
}

function getApiUrl() {
    let apiUrl;
    const currentURL = window.location.href;

    if (currentURL.includes("irisairmi.nike.com")) {
        // PROD
        apiUrl = "https://iris-api.airmi-prod.nikecloud.com";
    } else {
        // DEV & STAGE
        apiUrl = "https://iris-api.airmi-test.nikecloud.com";
    }

    return apiUrl;
}

export const issuerHost = getIssuerHost();
export const serverHost = getAuthHost();
export const apiUrl = getApiUrl();

export const oAuthConfig = {
    issuer: issuerHost,
    clientId: 'nike.airmi.iris-client-dev',
    authorizationUrlProd: serverHost,
    allowedIdleTime: 1800000 // (milliseconds) Value should be below 1 hour(Current OKTA token expiration time). This is the time by which application will wait for before labelling user as inactive.
};



