import React, { useEffect, useState } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { lab2rgb } from "../../Helpers/Helpers";

const LABTableRow = (props) => {
	const [RGB, setRGB] = useState(null);

	useEffect(() => {
		let rgb = lab2rgb(
			parseFloat(props.row.L),
			parseFloat(props.row.a),
			parseFloat(props.row.b)
		);
		setRGB(rgb);
	}, [props.row.L, props.row.a, props.row.b]);

	return (
		<TableRow key={props.row.prediction}>
			<TableCell component="th" scope="row" fontSize="10px">
				{props.row.prediction}
			</TableCell>
			<TableCell align="right">{props.row.npa}</TableCell>
			<TableCell align="right">{props.row.tbac}</TableCell>
			<TableCell align="right">{props.row.dye1}</TableCell>
			<TableCell align="right">{props.row.dye1conc}</TableCell>
			<TableCell align="right">{props.row.dye2}</TableCell>
			<TableCell align="right">{props.row.dye2conc}</TableCell>
			<TableCell align="right">{props.row.dye3}</TableCell>
			<TableCell align="right">{props.row.dye3conc}</TableCell>
			<TableCell align="right">{props.row.L}</TableCell>
			<TableCell align="right">{props.row.a}</TableCell>
			<TableCell align="right">{props.row.b}</TableCell>
			{props.groups.admin ? (
				<TableCell align="right">{props.row.dE}</TableCell>
			) : null}
			{props.groups.admin ? (
				<TableCell align="right">{props.row.R}</TableCell>
			) : null}
			{props.groups.admin ? (
				<TableCell align="right">{props.row.G}</TableCell>
			) : null}
			{props.groups.admin ? (
				<TableCell align="right">{props.row.B}</TableCell>
			) : null}
			{props.groups.admin ? (
				<TableCell align="right">{props.row.DL}</TableCell>
			) : null}
			{props.groups.admin ? (
				<TableCell align="right">{props.row.Da}</TableCell>
			) : null}
			{props.groups.admin ? (
				<TableCell align="right">{props.row.Db}</TableCell>
			) : null}
			{props.groups.admin ? (
				<TableCell align="right">{props.row.umean}</TableCell>
			) : null}
			{props.groups.admin ? (
				<TableCell align="right">{props.row.uL}</TableCell>
			) : null}
			{props.groups.admin ? (
				<TableCell align="right">{props.row.ua}</TableCell>
			) : null}
			{props.groups.admin ? (
				<TableCell align="right">{props.row.ub}</TableCell>
			) : null}
			{RGB ? (
				<TableCell align="right">
					<div
						className={"lab-color-cell"}
						style={{
							backgroundColor: `rgb(${RGB.R},${RGB.G},${RGB.B})`,
						}}
					/>
				</TableCell>
			) : null}
			{/* <ButtonStyled
				background="light"
				size="medium"
				ariaLabel="label"
				appearance="primary"
				onClick={() => {
					navigator.clipboard.writeText(
						`NPA: ${props.row.npa}, TBAC: ${props.row.tbac}, DYE1:${props.row.dye1}, DYE1CONC:${props.row.dye1conc}, DYE2: ${props.row.dye2}, DYE2CONC: ${props.row.dye2conc}, DYE3: ${props.row.dye3}, DYE3CONC: ${props.row.dye3conc}, L: ${props.row.L}, a: ${props.row.a}, b: ${props.row.b}, Delta E: ${props.row.dE}, R: ${props.row.R}, G: ${props.row.G}, B: ${props.row.B}`
					);
				}}
			>
				Button
			</ButtonStyled>
			<button
				onClick={() => {
					navigator.clipboard.writeText(
						`NPA: ${props.row.npa}, TBAC: ${props.row.tbac}, DYE1:${props.row.dye1}, DYE1CONC:${props.row.dye1conc}, DYE2: ${props.row.dye2}, DYE2CONC: ${props.row.dye2conc}, DYE3: ${props.row.dye3}, DYE3CONC: ${props.row.dye3conc}, L: ${props.row.L}, a: ${props.row.a}, b: ${props.row.b}, Delta E: ${props.row.dE}, R: ${props.row.R}, G: ${props.row.G}, B: ${props.row.B}`
					);
				}}
			></button> */}
		</TableRow>
	);
};

export default LABTableRow;
