import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const LABTableHeader = (props) => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>{props.titles[0]}</TableCell>
                {props.titles.slice(1).map( (title) => 
                    <TableCell align="right">{title}</TableCell>
                )}
            </TableRow>
        </TableHead>
    )
}

export default LABTableHeader;