import React, { Component } from "react";
import logo from "../../Assets/AirMI_Logo.png";

class Header extends Component {
	render() {
		return (
			<div>
				<div className="hero-image mb12-sm">
					<img src={logo} className="air-mi-logo" alt="Air Mi logo" />
				</div>
			</div>
		);
	}
}

export default Header;
