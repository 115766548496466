import React, { useState, useEffect, useCallback } from "react";
import DyeBox from "../Components/DyesToLabComponents/DyeBox";
import TextField from "@material-ui/core/TextField";
import { lab2rgb } from "../Helpers/Helpers";
import { dyeMenuItemForIndex } from "../Helpers/Helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faLongArrowAltRight,
	faExclamationCircle,
	faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { withStyles } from "@material-ui/core/styles";
// import RGBBox from "../Components/RGBBox";
import { ButtonStyled } from "@nike/nike-design-system-components";
import { Filter, Measure } from "@nike/nike-design-system-icons";
import { useOktaAuth } from "@okta/okta-react";
import { parseUserGroup } from "../Helpers/Helpers";
import { apiUrl } from "../Config/oAuthConfig";

const CssTextField = withStyles({
	root: {
		"& .MuiInput-underline:after": {
			borderBottomColor: "black",
			color: "black",
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "black",
				color: "black",
			},
			"&:hover fieldset": {
				borderColor: "black",
				color: "black",
			},
			"&.Mui-focused fieldset": {
				borderColor: "black",
				color: "black",
			},
		},
		"& .MuiOutlinedInput-input": {
			color: "black",
		},
		"& .MuiFormLabel-root": {
			color: "black",
		},
	},
})(TextField);

const DyesToLab = (props) => {
	const { oktaAuth, authState } = useOktaAuth();
	const myGroups =
		!authState || !authState.idToken ? null : parseUserGroup(authState);

	const [dyeConcentrations, setDyeConcentrations] = useState([0, 0, 0]);
	const [selectedDyes, setSelectedDyes] = useState([0, 0, 0]);
	const [npaConc, setNPAConc] = useState(15);
	const [tbacConc, setTBACConc] = useState(0);
	const [npaValid, setNPAValid] = useState(false);
	const [tbacValid, setTBACValid] = useState(false);
	const [formValid, setFormValid] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [result] = useState(undefined);
	const [L, setL] = useState(false);
	const [a, set_a] = useState(false);
	const [b, set_b] = useState(false);
	const [R, setR] = useState(false);
	const [G, setG] = useState(false);
	const [B, setB] = useState(false);
	const [RGB, setRGB] = useState(null);
	const [token, setToken] = React.useState("");
	const [helperConfig, setHelperConfig] = useState(null);
	const [url, setUrl] = useState("");
	const [dyeLimit, setDyeLimit] = useState("");

	useEffect(() => {
		if (authState.isAuthenticated) {
			const token = JSON.parse(window.localStorage["okta-token-storage"])
				.accessToken.accessToken;
			setToken(token);
			setUrl(apiUrl);
		} else {
			console.log("no token found");
			oktaAuth.signInWithRedirect();
		}
	}, [authState.isAuthenticated, oktaAuth]);


	useEffect(() => {
		if (token) {
			fetch(url + "/getHelperConfig", {
				method: "GET",
				headers: new Headers({
					Accept: "*/*",
					Authorization: `Bearer ${token}`,
				}),
			})
				.then((res) => res.json())
				.then((data) => {
					setHelperConfig(JSON.parse(data));
				})
				.catch((error) => console.log(error));
		}
	}, [token, url]);

	useEffect(() => {
		if (token) {
			fetch(url + "/readDyeLimits", {
				method: "GET",
				headers: new Headers({
					Accept: "*/*",
					Authorization: `Bearer ${token}`,
				}),
			})
				.then((res) => res.json())
				.then((data) => {
					setDyeLimit(data);
				})
				.catch((error) => console.log(error));
		}
	}, [token]);



	// eslint-disable-next-line react-hooks/exhaustive-deps
	const countValidDyeBoxes = useCallback(() => {
		let count = 0;
		selectedDyes.forEach((dye, index) => {
			if (dye !== 0 && dyeConcentrations[index] > 0.0) {
				count++;
			}
		});
		return count;
	});

	// Form validator
	useEffect(() => {
		let validBoxCount = countValidDyeBoxes();

		let bothZero = npaConc === 0.0 && tbacConc === 0.0;

		setFormValid(
			npaValid && tbacValid && validBoxCount > 0 && !bothZero && !error
		);
	}, [
		npaConc,
		tbacConc,
		tbacValid,
		npaValid,
		selectedDyes,
		dyeConcentrations,
		error,
		countValidDyeBoxes,
	]);

	// NPA Concentration validity
	useEffect(() => {
		setNPAValid(npaConc <= 100 && npaConc >= 0);
	}, [npaConc]);

	// TBAC Concentration validity
	useEffect(() => {
		setTBACValid(tbacConc <= 100.0 && tbacConc >= 0.0);
	}, [tbacConc]);

	// BEHOLD: THE POWER OF CURRYING
	const handleDyeConcentrationChange = (index) => {
		return (val) => {
			let newDyes = [...dyeConcentrations];
			newDyes[index] = val;
			setDyeConcentrations(newDyes);
		};
	};

	// BEHOLD: THE POWER OF CURRYING
	const handleDyeChange = (index) => {
		return (val) => {
			let newDyes = [...selectedDyes];
			newDyes[index] = val;
			setSelectedDyes(newDyes);
		};
	};

	const valueCallbacks = (index) => {
		let minVal = 0.07;
		let maxVal = 0.5;
		for (let i = 0; i < dyeLimit.length; i++) {
			if (dyeMenuItemForIndex(selectedDyes[index]).value === dyeLimit[i].Dye) {
				minVal = parseFloat(dyeLimit[i]["Lower Limit"])
				maxVal = parseFloat(dyeLimit[i]["Upper Limit"])
			}
		}
		return {
			sliderValue: dyeConcentrations[index],
			sliderValueCallback: handleDyeConcentrationChange(index),
			menuValue: selectedDyes[index],
			menuValueCallback: handleDyeChange(index),
			min: minVal,
			max: maxVal
		};
	};

	const handleNPAChange = (event) => {
		setNPAConc(parseInt(event.target.value));
	};

	const handleNPABlur = () => {
		if (npaConc < 0) {
			setNPAConc(0);
		} else if (npaConc > 100) {
			setNPAConc(100);
		}
	};

	const handleTBACBlur = () => {
		if (tbacConc < 0) {
			setTBACConc(0);
		} else if (tbacConc > 100) {
			setTBACConc(100);
		}
	};

	const handleTBACChange = (event) => {
		setTBACConc(parseFloat(event.target.value));
	};

	const handleSubmit = (event) => {
		if (formValid) {
			event.preventDefault();
			setLoading(true);

			console.log("Making API call!");

			const proxy_base = url + "/dyes";

			let params = `/{ovnpfxjyof}?tbacConc=${tbacConc}&npaConc=${npaConc}&dye1=${dyeMenuItemForIndex(selectedDyes[0]).value
				}&dye2=${dyeMenuItemForIndex(selectedDyes[1]).value}&dye3=${dyeMenuItemForIndex(selectedDyes[2]).value
				}&dye1Conc=${dyeConcentrations[0]}&dye2Conc=${dyeConcentrations[1]
				}&dye3Conc=${dyeConcentrations[2]}&numDyes=${countValidDyeBoxes()}`;

			fetch(proxy_base + params, {
				method: "GET",
				headers: new Headers({
					Accept: "*/*",
					Authorization: `Bearer ${token}`,
				}),
			})
				.then((res) => res.json())
				.then((response) => {
					// setCommitHistory(response.items);
					handleResponseAdmin(response);
				})
				.catch((error) => {
					setError(true);
					setLoading(false);
					console.log(error);
				});
		}
	};

	// const handleResponseUser = (response) => {
	// 	let rgb = lab2rgb(
	// 		parseInt(response[0]),
	// 		parseInt(response[1]),
	// 		parseInt(response[2])
	// 	);
	// 	setRGB(rgb);
	// 	setL(response[0]);
	// 	set_a(response[1]);
	// 	set_b(response[2]);
	// 	setLoading(false);
	// };

	const handleResponseAdmin = (response) => {
		let rgb = lab2rgb(
			parseInt(response[3]),
			parseInt(response[4]),
			parseInt(response[5])
		);
		setRGB(rgb);
		setR(response[0]);
		setG(response[1]);
		setB(response[2]);
		setL(response[3]);
		set_a(response[4]);
		set_b(response[5]);
		setLoading(false);
	};

	const handleErrorOk = (event) => {
		event.preventDefault();
		setError(false);
	};

	return (
		// <div className="mt12-sm mb12-sm" style={{ animation: "fadein 0.7s" }}>
		<div className="mt12-sm mb12-sm">
			<div className="page-title u-full-width d-sm-flx flx-jc-sm-c">
				<Measure className="mr2-sm" style={{ height: "55px", width: "55px" }} />
				<span className="display-1">DCS</span>
				<FontAwesomeIcon className="mr2-sm ml2-sm" icon={faLongArrowAltRight} />
				<Filter className="mr2-sm" style={{ height: "55px", width: "55px" }} />
				<span className="display-1">LAB</span>
			</div>

			<div className="page-info mt6-sm ta-sm-c">
				Fill in NPA concentration percentage, TBAC concentration in g/L, and at
				least one dye box to get started. <br />
				Use the 'Submit' button to run the model and preview results in RGB.
			</div>

			<div className="input-dye-boxes mt6-sm">
				<CssTextField
					className="input-dye-box"
					value={npaConc}
					onChange={handleNPAChange}
					onBlur={handleNPABlur}
					id="npaConc"
					name="npaConc"
					label="NPA"
					type="number"
					InputLabelProps={{
						shrink: true,
					}}
					variant="outlined"
					error={!npaValid}
					helperText={!npaValid ? "Must be between 0% and 100%" : ""}
					inputProps={{
						step: 1,
						min: 0,
						max: 100,
						type: "number",
					}}
				/>

				<CssTextField
					className="input-dye-box"
					value={tbacConc}
					onChange={handleTBACChange}
					onBlur={handleTBACBlur}
					id="tbacConc"
					name="tbacConc"
					label="TBAC (g/L)"
					type="number"
					InputLabelProps={{
						shrink: true,
					}}
					variant="outlined"
					error={!tbacValid}
					helperText={!tbacValid ? "Must be between 0 and 100" : ""}
					inputProps={{
						min: 0.0,
						max: 100.0,
						type: "number",
					}}
				/>
			</div>

			<div className="dye-boxes mt12-sm pl3-sm pr3-sm">
				<DyeBox title={"Dye 1"} color={"#FF5964"} {...valueCallbacks(0)} />
				<DyeBox title={"Dye 2"} color={"#6BF178"} {...valueCallbacks(1)} />
				<DyeBox title={"Dye 3"} color={"#35A7FF"} {...valueCallbacks(2)} />
			</div>
			{helperConfig ? (
				<div className="d-sm-flx flx-jc-sm-c">
					<div className="d-sm-flx flx-dir-sm-c flx-ai-sm-c mt10-sm">
						<div className="headline-2 mr12-sm">Iris File</div>
						<div className="body-2 mr12-sm">{helperConfig.iris}</div>
					</div>
					<div className="d-sm-flx flx-dir-sm-c flx-ai-sm-c mt10-sm">
						<div className="headline-2 mr12-sm">Dye List File</div>
						<div className="body-2 mr12-sm">{helperConfig.dyeList}</div>
					</div>
					<div className="d-sm-flx flx-dir-sm-c flx-ai-sm-c mt10-sm">
						<div className="headline-2">Dye Limit File</div>
						<div className="body-2">{helperConfig.dyeLimit}</div>
					</div>
				</div>
			) : null}
			<div className="u-full-width d-sm-flx flx-jc-sm-c mt10-sm">
				<ButtonStyled
					style={{ width: "200px" }}
					background="light"
					size="large"
					ariaLabel="label"
					appearance="primary"
					onClick={handleSubmit}
					disabled={!formValid ? true : false}
				>
					Submit
				</ButtonStyled>
			</div>

			{error ? (
				<div className="error-text ta-sm-c">
					<FontAwesomeIcon icon={faExclamationCircle} /> Something went wrong!
					Check console for more info
					<button className="error-button" onClick={handleErrorOk}>
						OK
					</button>
				</div>
			) : (
				<span />
			)}

			{loading ? (
				<div className="loading-text ta-sm-c">
					<FontAwesomeIcon icon={faSpinner} /> Loading ...
				</div>
			) : (
				<span />
			)}
			{L && (
				<div className="u-full-width d-sm-flx flx-jc-sm-sa flx-ai-sm-c mt8-sm">
					<div className="headline-1">
						<div>L: {L}</div>
						<div>a: {a}</div>
						<div>b: {b}</div>
					</div>

					<div
						className={"dcs-box"}
						style={{ backgroundColor: `rgb(${RGB.R},${RGB.G},${RGB.B})` }}
					/>

					{myGroups.admin ? (
						<div className="headline-1">
							<div>R: {R}</div>
							<div>G: {G}</div>
							<div>B: {B}</div>
						</div>
					) : null}
				</div>
			)}
		</div>
	);
};
export default DyesToLab;
