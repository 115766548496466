const dyeOptions = [
	{ value: "", display: "NONE" },
	{ value: "BemacidBlueE-3GC", display: "Bemacid Blue E-3GC" },
	{ value: "BemacidBlueN-5GL", display: "Bemacid Blue N-5GL" },
	{ value: "BemacidYellowE-4G", display: "Bemacid Yellow E-4G" },
	{ value: "BemacidYellowF-G", display: "Bemacid Yellow F-G" },
	{ value: "BemacidYellowN-2G", display: "Bemacid Yellow N-2G" },
	{ value: "ErionylBlueA-R", display: "Erionyl Blue A-R" },
	{ value: "ErionylNavyA-R", display: "Erionyl Navy A-R" },
	{ value: "ErionylRedA-2BF", display: "Erionyl Red A-2BF" },
	{ value: "ErionylRedA-3BN", display: "Erionyl Red A-3BN" },
	{ value: "ErionylRedB-10B", display: "Erionyl Red B-10B" },
	{ value: "ErionylYellowA-3G", display: "Erionyl Yellow A-3G" },
	{ value: "ErionylYellowA-R", display: "Erionyl Yellow A-R" },
	{ value: "LanasetBlue5G", display: "Lanaset Blue 5G" },
	{ value: "LanasetGreenB", display: "Lanaset Green B" },
	{ value: "LanasetYellow4GN", display: "Lanaset Yellow 4GN" },
	{ value: "LuminousRedGF", display: "Luminous Red GF" },
	{ value: "LuminousYellow8GFF", display: "Luminous Yellow 8GFF" },
	{ value: "OrasolBlackCN", display: "Orasol Black CN" },
	{ value: "OrasolBlackRLI", display: "Orasol Black RLI" },
	{ value: "ORCOAcidRed260", display: "ORCO Acid Red 260" },
	{ value: "TectilonBlue4R", display: "Tectilon Blue 4R" },
	{ value: "WaterPink2", display: "Water Pink 2" },
];

export function dyeMenuItemForIndex(index) {
	return dyeOptions[index];
}

export function getDyeOptions() {
	return dyeOptions;
}

export function lab2rgb(L, a, b) {
	var y = (L + 16) / 116,
		x = a / 500 + y,
		z = y - b / 200,
		red,
		green,
		blue;

	x = 0.95047 * (x * x * x > 0.008856 ? x * x * x : (x - 16 / 116) / 7.787);
	y = 1.0 * (y * y * y > 0.008856 ? y * y * y : (y - 16 / 116) / 7.787);
	z = 1.08883 * (z * z * z > 0.008856 ? z * z * z : (z - 16 / 116) / 7.787);

	red = x * 3.2406 + y * -1.5372 + z * -0.4986;
	green = x * -0.9689 + y * 1.8758 + z * 0.0415;
	blue = x * 0.0557 + y * -0.204 + z * 1.057;

	red = red > 0.0031308 ? 1.055 * Math.pow(red, 1 / 2.4) - 0.055 : 12.92 * red;
	green =
		green > 0.0031308
			? 1.055 * Math.pow(green, 1 / 2.4) - 0.055
			: 12.92 * green;
	blue =
		blue > 0.0031308 ? 1.055 * Math.pow(blue, 1 / 2.4) - 0.055 : 12.92 * blue;

	return {
		R: Math.max(0, Math.min(1, red)) * 255,
		G: Math.max(0, Math.min(1, green)) * 255,
		B: Math.max(0, Math.min(1, blue)) * 255,
	};
}



export function parseUserGroup(authState) {
	let openIdClaims = authState.idToken.claims;

	let groups = { admin: false, user: false, name: openIdClaims.email };

	if (
		openIdClaims.groups.indexOf("App.iris.adminnonprod") !== -1 ||
		openIdClaims.groups.indexOf("App.iris.admin") !== -1
	) {
		groups.admin = true;
	}
	if (
		openIdClaims.groups.indexOf("App.iris.usernonprod") !== -1 ||
		openIdClaims.groups.indexOf("App.iris.user") !== -1
	) {
		groups.user = true;
	}
	return groups;
}
