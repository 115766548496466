import React, { useEffect } from "react";
import Header from "../Components/HomeComponents/Header";
import logo from "../Assets/logo.svg";
import { useOktaAuth } from "@okta/okta-react";

const Home = () => {
	const { oktaAuth, authState } = useOktaAuth();
	useEffect(() => {
		if (!authState.isAuthenticated) {
			oktaAuth.signInWithRedirect();
			console.log("no token found");
		}
	}, [authState.isAuthenticated, oktaAuth]);

	return (
		<div className="d-sm-flx">
			<div className="home-page" style={{ animation: "fadein 0.7s" }}>
				<Header />
				<p className="iris-logo-text display-1 mb12-sm">IRIS</p>
				<div className="mt12-sm">
					<img src={logo} className="iris-app-logo App-logo" alt="logo" />
				</div>
			</div>
		</div>
	);
};

export default Home;
