import React, { useState } from "react";

function Timer({ hoursMinSecs }) {
	const { hours = 0, minutes = 0, seconds = 60 } = hoursMinSecs;
	const [[hrs, mins, secs], setTime] = useState([hours, minutes, seconds]);

	const tick = () => {
		if (hrs === 0 && mins === 0 && secs === 0) reset();
		else if (mins === 0 && secs === 0) {
			setTime([hrs - 1, 59, 59]);
		} else if (secs === 0) {
			setTime([hrs, mins - 1, 59]);
		} else {
			setTime([hrs, mins, secs - 1]);
		}
	};

	const reset = () =>
		setTime([parseInt(hours), parseInt(minutes), parseInt(seconds)]);

	React.useEffect(() => {
		const timerId = setInterval(() => tick(), 1000);
		return () => clearInterval(timerId);
	});

	return (
		<div className="d-sm-flx flx-dir-sm-c flx-ai-sm-c flx-jc-sm-c mt8-sm mb8-sm">
			<p className="display-3 mb4-sm">Results will be ready in:</p>
			<p className="display-2">{`${mins.toString()}:${secs
				.toString()
				.padStart(2, "0")}`}</p>
			<p className="headline-3 mb4-sm text-color-error">
				Please do not refresh screen.
			</p>
		</div>
	);
}

export default Timer;
