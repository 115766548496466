/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
	ButtonStyled,
	DropDownStyled,
} from "@nike/nike-design-system-components";
import TheDropzone from "../Components/UploadComponents/Dropzone";
import { useOktaAuth } from "@okta/okta-react";
import { parseUserGroup } from "../Helpers/Helpers";
import { apiUrl } from "../Config/oAuthConfig";

const Upload = (props) => {
	const { oktaAuth, authState } = useOktaAuth();
	const myGroups =
		!authState || !authState.idToken ? null : parseUserGroup(authState);
	const [token, setToken] = React.useState(null);
	const [irisFiles, setIrisFiles] = useState(null);
	const [selectedIris, setSelectedIris] = useState(null);
	const [dyeFiles, setDyeFiles] = useState(null);
	const [dyeLimitFiles, setDyeLimitFiles] = useState(null);
	const [lilyFiles, setLilyFiles] = useState(null);
	const [selectedDye, setSelectedDye] = useState(null);
	const [selectedDyeLimit, setSelectedDyeLimit] = useState(null);
	const [tempHelp, setTempHelp] = useState(null);
	const [selectedLily, setSelectedLily] = useState(null);
	const [configUpdated, setConfigUpdated] = useState(null);
	const [updateErrors, setUpdateErrors] = useState(true);
	const [url, setUrl] = useState("");


	useEffect(() => {
		if (authState.isAuthenticated) {
			const token = JSON.parse(window.localStorage["okta-token-storage"])
				.accessToken.accessToken;
			setToken(token);
			setUrl(apiUrl);
		} else {
			console.log("no token found");
			oktaAuth.signInWithRedirect();
		}
	}, [authState.isAuthenticated, oktaAuth]);

	useEffect(() => {
		if (token) {
			fetch(url + "/getHelperConfig", {
				method: "GET",
				headers: new Headers({
					Accept: "*/*",
					Authorization: `Bearer ${token}`,
				}),
			})

				.then((res) => res.json())
				.then((data) => {
					console.log(JSON.parse(data))
					setTempHelp(JSON.parse(data));
					setSelectedIris(JSON.parse(data).iris);
					setSelectedDye(JSON.parse(data).dyeList);
					setSelectedLily(JSON.parse(data).lily);
					setSelectedDyeLimit(JSON.parse(data).dyeLimit);
				})
				.catch((error) => console.log(error));
		}
	}, [token]);

	const getIrisFiles = () => {

		fetch(url + "/getIrisFiles", {
			method: "GET",
			headers: new Headers({
				Accept: "*/*",
				Authorization: `Bearer ${token}`,
			}),
		})

			.then((res) => {
				return res.json();
			})
			.then((data) => {
				setIrisFiles(
					data.map((item, index) => {
						return { key: index, value: item, label: item };
					})
				);
			})
			.catch((error) => console.log(error));
	};

	useEffect(() => {
		if (token) {
			getIrisFiles();
		}
	}, [token]);

	const getDyeFiles = () => {

		fetch(url + "/getDyeFiles", {
			method: "GET",
			headers: new Headers({
				Accept: "*/*",
				Authorization: `Bearer ${token}`,
			}),
		})

			.then((res) => {
				return res.json();
			})
			.then((data) => {
				setDyeFiles(
					data.map((item, index) => {
						return { key: index, value: item, label: item };
					})
				);
			})
			.catch((error) => console.log(error));
	};

	useEffect(() => {
		if (token) {
			getDyeFiles();
		}
	}, [token]);

	const getDyeLimitFiles = () => {

		fetch(url + "/getDyeLimitFiles", {
			method: "GET",
			headers: new Headers({
				Accept: "*/*",
				Authorization: `Bearer ${token}`,
			}),
		})

			.then((res) => {
				return res.json();
			})
			.then((data) => {
				setDyeLimitFiles(
					data.map((item, index) => {
						return { key: index, value: item, label: item };
					})
				);
			})
			.catch((error) => console.log(error));
	};

	useEffect(() => {
		if (token) {
			getDyeLimitFiles();
		}
	}, [token]);

	const getLilyFiles = () => {

		fetch(url + "/getLilyFiles", {
			method: "GET",
			headers: new Headers({
				Accept: "*/*",
				Authorization: `Bearer ${token}`,
			}),
		})

			.then((res) => {
				return res.json();
			})
			.then((data) => {
				setLilyFiles(
					data.map((item, index) => {
						return { key: index, value: item, label: item };
					})
				);
			})
			.catch((error) => console.log(error));
	};

	useEffect(() => {
		if (token) {
			getLilyFiles();
		}
	}, [token]);

	const setHelperConfigS3 = (event) => {
		let raw;
		if(event.target.name=="iris"){
			raw = JSON.stringify({
				dyeList: selectedDye,
				dyeLimit: tempHelp.dyeLimit,
				iris: selectedIris,
				lily: tempHelp.lily
			});
		}
		else{
			raw = JSON.stringify({
				dyeList: tempHelp.dyeList,
				dyeLimit: selectedDyeLimit,
				iris: tempHelp.iris,
				lily: selectedLily
			});
		}		

		let requestOptions = {
			method: "POST",
			headers: new Headers({
				Accept: "*/*",
				Authorization: `Bearer ${token}`,
				"Content-Type":'application/json'
			}),
			body: raw,
		};

		event.preventDefault();

		fetch(url + "/setHelperConfig", requestOptions)

			.then((result) => {
				setConfigUpdated("Updated successfully");
				setUpdateErrors(false);
			})
			.catch((error) => {
				setConfigUpdated("Update failed. Try again.");
				setUpdateErrors(true);
				console.log(error);
			});
		getIrisFiles();
		getDyeFiles();
		getLilyFiles();
		getDyeLimitFiles();
	};

	const refreshFiles = () => {
		getIrisFiles();
		getDyeFiles();
		getLilyFiles();
		getDyeLimitFiles();
	}

	return (
		<div >
			{myGroups.admin ? (
				<div className="d-sm-flx flx-dir-sm-c flx-jc-sm-c flx-ai-sm-c">
					<TheDropzone success={refreshFiles}/>
					<div className="headline-2 ta-sm-c mt6-sm mb6-sm">
						Choose files to update Iris for users
					</div>
					<div  style={{ display: "flex" }}>
						<DropDownStyled
							style={{ height: "0px", width: "450px", margin: "10px" }}
							value={selectedIris}
							onChange={(event) => {
								setSelectedIris(event.target.value);
							}}
							ariaLive="polite"
							autoComplete="off"
							id="iris file"
							label="Iris file"
							name="Iris File"
							options={irisFiles ? irisFiles : []}
							placeholder="Choose an Iris file"
							requiredIndicator="*"
							supportText="Select a Iris file to use"
							disabled={myGroups.admin ? false : true}
						/>
						<DropDownStyled
							style={{ height: "0px", width: "450px", margin: "10px" }}
							value={selectedDye}
							onChange={(event) => {
								setSelectedDye(event.target.value);
							}}
							ariaLive="polite"
							autoComplete="off"
							id="dye list file"
							label="Dye List file"
							name="Dye List File"
							options={dyeFiles ? dyeFiles : []}
							placeholder="Choose a Dye List file"
							requiredIndicator="*"
							supportText="Select a Dye List file to use"
							disabled={myGroups.admin ? false : true}
						/>
						<ButtonStyled
							style={{ height: "0px", width: "200px" }}
							className="mt3-sm"
							background="light"
							size="medium"
							ariaLabel="label"
							appearance="primary"
							name="iris"
							onClick={setHelperConfigS3}
						>
							Update
						</ButtonStyled>
					</div>
					<br/>
					<div style={{ display: "flex" }}>
						<DropDownStyled
							style={{ height: "0px", width: "450px", margin: "10px"	}}
							value={selectedLily}
							onChange={(event) => {
								setSelectedLily(event.target.value);
							}}
							ariaLive="polite"
							autoComplete="off"
							id="lily file"
							label="Lily file"
							name="Lily File"
							options={lilyFiles ? lilyFiles : []}
							placeholder="Choose a Lily file"
							requiredIndicator="*"
							supportText="Select a Lily file to use"
							disabled={myGroups.admin ? false : true}
						/>
							<DropDownStyled
							style={{ height: "0px", width: "450px", margin: "10px" }}
							value={selectedDyeLimit}
							onChange={(event) => {
								setSelectedDyeLimit(event.target.value);
							}}
							ariaLive="polite"
							autoComplete="off"
							id="dye limit file"
							label="Dye Limit file"
							name="Dye Limit File"
							options={dyeLimitFiles ? dyeLimitFiles : []}
							placeholder="Choose a Dye Limit file"
							requiredIndicator="*"
							supportText="Select a Dye Limit file to use"
							disabled={myGroups.admin ? false : true}
						/>
						<ButtonStyled
							style={{ height: "0px", width: "200px" }}
							className="mt3-sm"
							background="light"
							size="medium"
							ariaLabel="label"
							appearance="primary"
							name="lily"
							onClick={setHelperConfigS3}
						>
							Update
						</ButtonStyled>
					</div>
					{configUpdated ? (
						<div
							className={`headline-2 ta-sm-c mt6-sm mb6-sm ${
								updateErrors ? "text-color-error" : "text-color-success"
							}`}
						>
							{configUpdated}
						</div>
					) : null}
				</div>
			) : (
				<div className="d-sm-flx flx-dir-sm-c flx-jc-sm-c flx-ai-sm-c u-full-height">
					<div className="display-1">NOT AUTHORIZED USER</div>
				</div>
			)}
		</div>
	);
};

export default Upload;
