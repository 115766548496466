import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input';
import Opacity from '@material-ui/icons/Opacity';
import { memo } from 'react';

const DyeSlider = memo(({title="", min=0.0, max=1.0, step=0.01, value, valueCallback, color="#FF0000"}) => {

    const handleSliderChange = (event, newValue) => {
        valueCallback(newValue);
    };

    const handleInputChange = (event) => {
        valueCallback(event.target.value === '' ? 0 : Number(event.target.value));
      };

    const handleBlur = () => {
        if (value < min) {
            valueCallback(min);
        } else if (value > max) {
            valueCallback(max);
        }
    };

    return (
    <div>
      <Typography id="input-slider" gutterBottom>
        {title}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Opacity style={{color: color}} />
        </Grid>
        <Grid item xs>
          <Slider
            value={typeof value === 'number' ? value : 0}
            step={step}
            style={{color: color}}
            min={min}
            max={max}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
          />
        </Grid>
        <Grid item>
          <Input
            value={value}
            style={{color: color}}
            margin="dense"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: step,
              min: min,
              max: max,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
});

export default DyeSlider;