import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { parseUserGroup } from "../../Helpers/Helpers";
import { Home, Filter, Measure, Share, Show } from "@nike/nike-design-system-icons";
import { NikeApp } from "@nike/nike-design-system-icons";
import Tooltip from "./Tooltip/Tooltip";
import "../../App.css";

const ToolBar = () => {
	const [currentPage, setCurrentPage] = useState(false);
	const { oktaAuth, authState } = useOktaAuth();

	const myGroups =
		!authState || !authState.idToken ? null : parseUserGroup(authState);

	useEffect(() => {
		setCurrentPage(window.location.pathname);
	}, []);

	useEffect(() => {
		if (authState && !authState.isAuthenticated) {
			console.log("no token found");
			oktaAuth.signInWithRedirect();
		}
	}, [authState, oktaAuth]);

	return (
		<Fragment>
			<div className="nav-bar bg-black d-sm-flx flx-dir-sm-c flx-jc-sm-sb flx-ai-sm-c">
				<div className="mt7-sm">
					<NikeApp style={{ height: "50px", width: "50px", color: "white" }} />
					<div
						className="body-2 ta-sm-c"
						style={{ color: "white", lineHeight: "0px" }}
					>
						IRIS
					</div>
				</div>

				<div
					className="d-sm-flx flx-dir-sm-c flx-jc-sm-s flx-ai-sm-c"
					style={{ height: "100%" }}
				>
					<div
						className={
							currentPage === "/"
								? "headline-4 d-sm-flx flx-jc-sm-c flx-ai-sm-c mt12-sm nav-selected-icon"
								: "headline-4 d-sm-flx flx-jc-sm-c flx-ai-sm-c mt12-sm nav-unselected-icon"
						}
					>
						<Tooltip content="Home" direction="right">
							<Link to="/" onClick={() => setCurrentPage("/")}>
								<Home
									style={{ width: "30px", height: "30px", color: "white" }}
								/>
							</Link>
						</Tooltip>
					</div>
					<div
						className={
							currentPage === "/dyeToLAB"
								? "headline-4 d-sm-flx flx-jc-sm-c flx-ai-sm-c mt12-sm nav-selected-icon"
								: "headline-4 d-sm-flx flx-jc-sm-c flx-ai-sm-c mt12-sm nav-unselected-icon"
						}
					>
						<Tooltip content="DCS to LAB" direction="right">
							<Link to="/dyeToLAB" onClick={() => setCurrentPage("/dyeToLAB")}>
								<Filter
									style={{ width: "30px", height: "30px", color: "white" }}
								/>
							</Link>
						</Tooltip>
					</div>
					<div
						className={
							currentPage === "/fromLAB"
								? "headline-4 d-sm-flx flx-jc-sm-c flx-ai-sm-c mt12-sm nav-selected-icon"
								: "headline-4 d-sm-flx flx-jc-sm-c flx-ai-sm-c mt12-sm nav-unselected-icon"
						}
					>
						<Tooltip content="LAB to DCS" direction="right">
							<Link to="/fromLAB" onClick={() => setCurrentPage("/fromLAB")}>
								<Measure
									style={{ width: "30px", height: "30px", color: "white" }}
								/>
							</Link>
						</Tooltip>
					</div>
					<div
						className={
							currentPage === "/qtxToLAB"
								? "headline-4 d-sm-flx flx-jc-sm-c flx-ai-sm-c mt12-sm nav-selected-icon"
								: "headline-4 d-sm-flx flx-jc-sm-c flx-ai-sm-c mt12-sm nav-unselected-icon"
						}
					>
						<Tooltip content="QTX to LAB" direction="right">
							<Link to="/qtxToLAB" onClick={() => setCurrentPage("/qtxToLAB")}>
								<Show
									style={{ width: "30px", height: "30px", color: "white" }}
								/>
							</Link>
						</Tooltip>
					</div>
					{myGroups && myGroups.admin ? (
						<div
							className={
								currentPage === "/upload"
									? "headline-4 d-sm-flx flx-jc-sm-c flx-ai-sm-c mt12-sm nav-selected-icon"
									: "headline-4 d-sm-flx flx-jc-sm-c flx-ai-sm-c mt12-sm nav-unselected-icon"
							}
						>
							<Tooltip content="Upload" direction="right">
								<Link to="/upload" id="uploadlink" onClick={() => setCurrentPage("/upload")}>
									<Share
										style={{ width: "30px", height: "30px", color: "white" }}
									/>
								</Link>
							</Tooltip>
						</div>
					) : null}
				</div>

				{myGroups ? (
					<Tooltip
						content={myGroups.admin ? "Admin" : "User"}
						direction="right"
					>
						<div
							className="headline-4 mb10-sm mt10-sm nav-initial"
							style={{ color: "white" }}
						>
							<span>{myGroups.name[0]}</span>
						</div>
					</Tooltip>
				) : (
					<Tooltip content={"No User Signed In"} direction="right">
						<div
							className="headline-4 mb10-sm nav-initial"
							style={{ color: "white" }}
						>
							<span></span>
						</div>
					</Tooltip>
				)}
			</div>
		</Fragment>
	);
};
export default ToolBar;
