import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { memo } from 'react';
import { getDyeOptions, dyeMenuItemForIndex } from '../../Helpers/Helpers';
import { ArrowDropDown } from '@material-ui/icons';

const DyeMenu = memo(({title, value, valueCallback}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
    const handleMenuItemClick = (event, index) => {
        valueCallback(index);
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const item = dyeMenuItemForIndex(value);

    // Have to conditionally render to avoid tanking the webpage
    // It'll attempt to render the invisible list which takes a second

    return(
    <div>
        <div className="dye-menu-button" onClick={handleClickListItem}> { (item.display === "NONE" ? title : item.display) } <ArrowDropDown className={"menu-downarrow"}/> </div>
        { Boolean(anchorEl) ?
        <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}> {getDyeOptions().map((option, index) => (
                <MenuItem
                    key={option.value}
                    selected={index === value}
                    onClick={(event) => handleMenuItemClick(event, index)}>
                {option.display}
                </MenuItem>))} 
        </Menu> : ""}
    </div>
    );
});

export default DyeMenu;