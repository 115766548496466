import React, { useEffect, useState, Fragment } from "react";
import "./App.css";
import { Switch, Route, useHistory } from "react-router-dom";
import FromLab from "./Pages/LabToDyes";
import DyesToLab from "./Pages/DyesToLAB";
import { oAuthConfig } from "./Config/oAuthConfig";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import Home from "./Pages/Home";
import ToolBar from "./Components/NavComponents/Toolbar";
import Upload from "./Pages/Upload";
import { NikeDesignSystemProvider } from "@nike/nike-design-system-components";
import QtxToLab from "./Pages/QtxToLab";

const config = {
	issuer: oAuthConfig.issuer,
	redirectUri: window.location.origin + "/implicit/callback",
	clientId: oAuthConfig.clientId,
	pkce: true,
	response_type: ["code"],
};

const oktaAuth = new OktaAuth(config);

const App = () => {
	const history = useHistory();
	const restoreOriginalUri = async (_oktaAuth, originalUri) => {
		history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
	};

	return (
		<Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
			<NikeDesignSystemProvider>
				<Switch>
					<Fragment>
						<Route path="/implicit/callback" component={LoginCallback} />
						<div className="d-sm-flx">
							<ToolBar />
							<div className="page-body">
								<SecureRoute
									exact={true}
									path="/"
									render={(props) => <Home {...props} />}
								/>
								<SecureRoute
									exact={true}
									path="/fromLAB"
									render={(props) => <FromLab {...props} />}
								/>
								<SecureRoute
									exact={true}
									path="/dyeToLAB"
									render={(props) => <DyesToLab {...props} />}
								/>
								<SecureRoute
									exact={true}
									path="/qtxToLAB"
									render={(props) => <QtxToLab {...props} />}
								/>
								<SecureRoute
									exact={true}
									path="/upload"
									render={(props) => <Upload {...props} />}
								/>
							</div>
						</div>
					</Fragment>
				</Switch>
			</NikeDesignSystemProvider>
		</Security>
	);
};

export default App;
