import React from "react";
import DyeSlider from "./DyeSlider";
import DyeMenu from "./DyeMenu";
import { memo } from "react";

const DyeBox = memo(
	({
		title,
		color,
		sliderValue,
		sliderValueCallback,
		menuValue,
		menuValueCallback,
		min,
		max
	}) => {
		const on = menuValue !== 0 && sliderValue > 0.0;
		return (
			<div
				style={{ border: on ? "1px solid " + color : "1px solid black" }}
				className={"dye-box" + (on ? "" : " half-trans")}
			>
				<div className="dye-box-flavor">{title}</div>
				<DyeMenu
					title={title + " Name"}
					value={menuValue}
					valueCallback={menuValueCallback}
				/>
				<DyeSlider
					title={title + " Concentration"}
					value={sliderValue}
					valueCallback={sliderValueCallback}
					color={color}
					min={min}
					max={max}
				/>
			</div>
		);
	}
);

export default DyeBox;
