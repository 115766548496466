import React, { useState, useEffect, useMemo } from "react";
import { ButtonStyled } from "@nike/nike-design-system-components";
import { useDropzone } from "react-dropzone";
import { useOktaAuth } from "@okta/okta-react";
import { apiUrl } from "../../Config/oAuthConfig";

// We are using axios as the http library
const baseStyle = {
	flex: 1,
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	paddingTop: "90px",
	paddingBottom: "90px",
	borderWidth: 2,
	borderRadius: 2,
	borderColor: "#eeeeee",
	borderStyle: "dashed",
	backgroundColor: "#fafafa",
	color: "#bdbdbd",
	outline: "none",
	transition: "border .24s ease-in-out",
};

const activeStyle = {
	borderColor: "#2196f3",
};

const acceptStyle = {
	borderColor: "#00e676",
};

const rejectStyle = {
	borderColor: "#ff1744",
};

const FileUpload = (props) => {
	// fileToUpload contains the actual file object
	// uploadSuccess becomes true when the file upload is complete
	const [fileToUpload, setFilToUpload] = React.useState(null);
	const [uploadSuccess, setUploadSuccess] = useState(null);
	const [error, setError] = useState(null);
	const [token, setToken] = useState(null);
	const { oktaAuth, authState } = useOktaAuth();
	const {
		acceptedFiles,
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject,
	} = useDropzone({
		onDrop: (files) => {
			setFilToUpload(files[0]);
		},
	});

	const style = useMemo(
		() => ({
			...baseStyle,
			...(isDragActive ? activeStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {}),
		}),
		[isDragActive, isDragReject, isDragAccept]
	);

	useEffect(() => {
		if (authState.isAuthenticated) {
			const token = JSON.parse(window.localStorage["okta-token-storage"])
				.accessToken.accessToken;
			setToken(token);
		} else {
			console.log("No Token Found.");
			oktaAuth.signInWithRedirect();
		}
	}, [authState.isAuthenticated, oktaAuth]);
	useEffect(()=>{
		if(uploadSuccess){
			props.success()
		}
	}, [uploadSuccess])
	const files = acceptedFiles.map((file) => (
		<li key={file.path}>
			{file.path} - {file.size} bytes
		</li>
	));

	const uploadFile = () => {
		// When the upload file button is clicked,
		// first we need to get the presigned URL
		// URL is the one you get from AWS API Gateway
		const url = apiUrl;

		fetch(
			url +
				"/sendIrisFile?fileName=" +
				fileToUpload.name +
				"&fileType=" +
				fileToUpload.type,
			{
				method: "GET",
				headers: new Headers({
					Accept: "*/*",
					Authorization: `Bearer ${token}`,
				}),
			}
		)
			.then((response) => {
				// Getting the url from response
				return response.text();
			})
			.then((s3url) => {
				const url = s3url;
				// Initiating the PUT request to upload file
				fetch(s3url, {
					method: "PUT",
					headers: { "Content-Type": fileToUpload.type },
					body: fileToUpload,
				})
					.then((res) => {
						setUploadSuccess("File uploaded successfully");
						setError(null);
						setFilToUpload(null);
					})
					.catch((err) => {
						setUploadSuccess(null);
						setError("Error Occured while uploading the file");
						setFilToUpload(null);
					});
			});
	};
	return (
		<div className="fileUploadCont">
			<div className="headline-2 ta-sm-c mb6-sm">
				Upload new IRIS file to S3
			</div>
			<div>
				<section className="bg-primary">
					<div {...getRootProps({ style })}>
						<input {...getInputProps()} />
						<p>Drag 'n' drop .sav files here, or click to select files</p>
					</div>
					{fileToUpload ? (
						<aside className="mt6-sm ta-sm-c">
							<h4>Files</h4>
							<ul>{files}</ul>
						</aside>
					) : null}
				</section>
				{fileToUpload ? (
					<div className="d-sm-flx flx-jc-sm-c">
						<ButtonStyled
							style={{ height: "0px", width: "200px" }}
							className="mt12-sm mr6-sm cancel"
							background="light"
							size="medium"
							ariaLabel="label"
							appearance="secondary"
							onClick={(e) => {
								setFilToUpload(null);
							}}
						>
							Cancel
						</ButtonStyled>
						<ButtonStyled
							style={{ height: "0px", width: "200px" }}
							className="mt12-sm"
							background="light"
							size="medium"
							ariaLabel="label"
							appearance="primary"
							onClick={(e) => {
								uploadFile();
							}}
						>
							Upload
						</ButtonStyled>
					</div>
				) : (
					<div className="d-sm-flx flx-jc-sm-c">
						<ButtonStyled
							style={{ height: "0px", width: "200px" }}
							className="mt12-sm mr6-sm"
							background="light"
							size="medium"
							ariaLabel="label"
							appearance="secondary"
							disabled={true}
						>
							Cancel
						</ButtonStyled>
						<ButtonStyled
							style={{ height: "0px", width: "200px" }}
							className="mt12-sm"
							background="light"
							size="medium"
							ariaLabel="label"
							appearance="primary"
							disabled={true}
						>
							Upload
						</ButtonStyled>
					</div>
				)}
				{error ? (
					<div className="headline-2 ta-sm-c mt6-sm text-color-error">
						{error}
					</div>
				) : null}
				{uploadSuccess ? (
					<div className="headline-2 ta-sm-c mt6-sm text-color-success">
						{uploadSuccess}
					</div>
				) : null}
			</div>
		</div>
	);
};

export default FileUpload;
