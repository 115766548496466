import React, { useState, useEffect, useCallback, useMemo } from "react";
import DyeBox from "../Components/DyesToLabComponents/DyeBox";
import TextField from "@material-ui/core/TextField";
import { lab2rgb } from "../Helpers/Helpers";
import { dyeMenuItemForIndex } from "../Helpers/Helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faLongArrowAltRight,
	faExclamationCircle,
	faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { withStyles } from "@material-ui/core/styles";
// import RGBBox from "../Components/RGBBox";
import { ButtonStyled } from "@nike/nike-design-system-components";
import { Filter, Show } from "@nike/nike-design-system-icons";
import { useOktaAuth } from "@okta/okta-react";
import { parseUserGroup } from "../Helpers/Helpers";
import { useDropzone } from "react-dropzone";
import { apiUrl } from "../Config/oAuthConfig";

const CssTextField = withStyles({
	root: {
		"& .MuiInput-underline:after": {
			borderBottomColor: "black",
			color: "black",
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "black",
				color: "black",
			},
			"&:hover fieldset": {
				borderColor: "black",
				color: "black",
			},
			"&.Mui-focused fieldset": {
				borderColor: "black",
				color: "black",
			},
		},
		"& .MuiOutlinedInput-input": {
			color: "black",
		},
		"& .MuiFormLabel-root": {
			color: "black",
		},
	},
})(TextField);
const baseStyle = {
	flex: 1,
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	padding: "10px",
	borderWidth: 2.5,
	borderRadius: 2,
	borderColor: "#eeeeee",
	borderStyle: "dashed",
	backgroundColor: "#fafafa",
	color: "#bdbdbd",
	outline: "none",
	transition: "border .24s ease-in-out",
};

const activeStyle = {
	borderColor: "#2196f3",
};

const acceptStyle = {
	borderColor: "#00e676",
};

const rejectStyle = {
	borderColor: "#ff1744",
};

const QtxToLab = (props) => {
	const { oktaAuth, authState } = useOktaAuth();
	const myGroups =
		!authState || !authState.idToken ? null : parseUserGroup(authState);
	const {
		acceptedFiles,
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject,
	} = useDropzone({
		onDrop: (files) => {
			setFilToUpload(files[0]);
			checkFormValid();
		},
	});
	const style = useMemo(
		() => ({
			...baseStyle,
			...(isDragActive ? activeStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {}),
		}),
		[isDragActive, isDragReject, isDragAccept]
	);
	const files = acceptedFiles.map(file => (
		<li key={file.path}>
			{file.path} - {file.size} bytes
		</li>
	));
	const [fileToUpload, setFilToUpload] = useState(null);
	const [colorCode, setColorCode] = useState("");

	const [dyeConcentrations, setDyeConcentrations] = useState([0, 0, 0]);
	const [selectedDyes, setSelectedDyes] = useState([0, 0, 0]);
	const [npaConc, setNPAConc] = useState(15);
	const [npaValid, setNPAValid] = useState(false);
	const [tbacValid, setTBACValid] = useState(false);
	const [formValid, setFormValid] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [result] = useState(undefined);
	const [L, setL] = useState(false);
	const [a, set_A] = useState(false);
	const [b, set_B] = useState(false);
	const [R, setR] = useState(false);
	const [G, setG] = useState(false);
	const [B, setB] = useState(false);
	const [fabric, setFabric] = useState(false);
	const [RGB, setRGB] = useState(lab2rgb(
		parseInt(L),
		parseInt(a),
		parseInt(b)
	));
	const [token, setToken] = React.useState("");
	const [helperConfig, setHelperConfig] = useState(null);
	const [url, setUrl] = useState("");

	useEffect(() => {
		if (authState.isAuthenticated) {
			const token = JSON.parse(window.localStorage["okta-token-storage"])
				.accessToken.accessToken;
			setToken(token);
			setUrl(apiUrl);
			} else {
			console.log("no token found");
			oktaAuth.signInWithRedirect();
		}
	}, [authState.isAuthenticated, oktaAuth]);

	useEffect(() => {
		if (token) {
			fetch(url + "/getHelperConfig", {
				method: "GET",
				headers: new Headers({
					Accept: "*/*",
					Authorization: `Bearer ${token}`,
				}),
			})
				.then((res) => res.json())
				.then((data) => {
					setHelperConfig(JSON.parse(data));
				})
				.catch((error) => console.log(error));
		}
	}, [token, url]);

	// eslint-disable-next-line react-hooks/exhaustive-deps

	const handleColorChange = (event) => {
		setColorCode(event.target.value);
		checkFormValid()
	};

	const checkFormValid = () => {
		if(colorCode?.length>0 && fileToUpload){
			setFormValid(true)
		}
		else{
			setFormValid(false)
		}
	}

	useEffect(()=>{
		checkFormValid()
	},[colorCode,fileToUpload])

	const handleQtxConversion = () => {
		let params = "?colorCode="+colorCode
		setLoading(true)
		fetch(url + "/qtxToLab"+params, {
			method: "POST",
			headers: new Headers({
				Accept: "*/*",
				Authorization: `Bearer ${token}`,
			}),
			body: fileToUpload
		})
		.then(response => response.json() )
		.then(responseJson => {
			console.log(responseJson)
			if(typeof responseJson != "object"){
				setError(true)
				setL(false)
				set_A(false)
				set_B(false)
				setR(false)
				setG(false)
				setB(false)
				setRGB(false)
			}
			else{
			let obj = responseJson["airbag"]
			setFabric(responseJson["fabric"])
			setError(false)
			setL(obj["lab"][0])
			set_A(obj["lab"][1])
			set_B(obj["lab"][2])
			setR(obj["rgb"][0])
			setG(obj["rgb"][1])
			setB(obj["rgb"][2])
			setRGB(lab2rgb(
				parseInt(obj["lab"][0]),
				parseInt(obj["lab"][1]),
				parseInt(obj["lab"][2])
			))
			}
			setLoading(false)
		})
		.catch(error=>{
			console.log(error)
			setLoading(false)
		})
	}
	const handleErrorOk = (event) => {
		event.preventDefault();
		setError(false);
	};

	return (
		// <div className="mt12-sm mb12-sm" style={{ animation: "fadein 0.7s" }}>
		<div className="mt12-sm mb12-sm">
			<div className="page-title u-full-width d-sm-flx flx-jc-sm-c">
				<Show className="mr2-sm" style={{ height: "55px", width: "55px" }} />
				<span className="display-1">QTX</span>
				<FontAwesomeIcon className="mr2-sm ml2-sm" icon={faLongArrowAltRight} />
				<Filter className="mr2-sm" style={{ height: "55px", width: "55px" }} />
				<span className="display-1">LAB</span>
			</div>

			<div className="page-info mt6-sm ta-sm-c">
				Upload QTX file and fill the color code, Use Calculate button to view the LAB values. <br />
				Click Convert to Iris button to navigate and view DCS conversion.
			</div>
			<br/>
			<div className="d-sm-flx flx-jc-sm-sa flx-ai-sm-c">
				<section className="bg-primary">
					<div {...getRootProps({ style })}>
						<input {...getInputProps()} />
						<p>Upload QTX File</p>
					</div>
					{fileToUpload ? (
						<aside className="mt6-sm ta-sm-c">
							<h4>Files</h4>
							<ul>{files}</ul>
						</aside>
					) : null}
				</section>
				<CssTextField
					className="input-color-box"
					value={colorCode}
					onChange={handleColorChange}
					onBlur={handleColorChange}
					id="colorCode"
					name="colorCode"
					label="Color Code"
					placeholder="eg: 6FX"
					InputLabelProps={{
						shrink: true,
					}}
					variant="outlined"
				/>
				<ButtonStyled
					style={{ width: "200px" }}
					background="light"
					size="large"
					ariaLabel="label"
					appearance="primary"
					onClick={handleQtxConversion}
					disabled={!formValid ? true : false}
					id="qtxConvert"
				>
					Calculate
				</ButtonStyled>
			</div>

			{helperConfig ? (
				<div className="d-sm-flx flx-jc-sm-c">
					<div className="d-sm-flx flx-dir-sm-c flx-ai-sm-c mt10-sm">
						<div className="headline-2 mr12-sm">QTX File</div>
						<div className="body-2 mr12-sm">{helperConfig.lily}</div>
					</div>
				</div>
			) : null}
			

			{error ? (
				<div className="error-text ta-sm-c">
					<FontAwesomeIcon icon={faExclamationCircle} /> Something went wrong!
					Check console for more info
					<button className="error-button" onClick={handleErrorOk}>
						OK
					</button>
				</div>
			) : (
				<span />
			)}

			{loading ? (
				<div className="loading-text ta-sm-c">
					<FontAwesomeIcon icon={faSpinner} /> Loading ...
				</div>
			) : (
				<span />
			)}
			<br/>
			{L && (
				<>
				<div className="u-full-width d-sm-flx flx-jc-sm-sa flx-ai-sm-c mt8-sm headline-1">
					
					{/* Fabric */}
					<div style={{textAlign:"center"}}>
						FABRIC
					<div
						className={"fabric-box"}
						style={{ backgroundColor: `rgb(${fabric["rgb"][0]},${fabric["rgb"][1]},${fabric["rgb"][2]})` }}
					/>
					<div className="cl-text">
						{/* <div>L: {Number(L).toFixed(2)}</div>
						<div>a: {Number(a).toFixed(2)}</div>
						<div>b: {Number(b).toFixed(2)}</div> */}
						<div><b>LAB: </b>{Number(fabric["lab"][0]).toFixed(2)},{Number(fabric["lab"][1]).toFixed(2)},{Number(fabric["lab"][2]).toFixed(2)}</div>
						<div><b>RGB: </b>{fabric["rgb"][0]},{fabric["rgb"][1]},{fabric["rgb"][2]}</div>
					</div>
					</div>
					{/* Air Bag */}
					<div style={{textAlign:"center"}}>
						AIRBAG
					<div
						className={"fabric-box"}
						style={{ backgroundColor: `rgb(${R},${G},${B})` }}
					/>
					<div className="cl-text">
						<div><b>LAB: </b>{Number(L).toFixed(2)},{Number(a).toFixed(2)},{Number(b).toFixed(2)}</div>
						<div><b>RGB: </b>{R},{G},{B}</div>
					</div>
					</div>
				</div>
			<br/>
			<div className="u-full-width d-sm-flx flx-jc-sm-sa">
			<ButtonStyled
					style={{ width: "200px" }}
					background="light"
					size="large"
					ariaLabel="label"
					appearance="primary"
					onClick={()=>props.history.push({
						pathname: '/fromLAB',
						state: { l:L,a:a,b:b }
					  })}
					disabled={L?false:true}
				>
					Convert to DCS
				</ButtonStyled>
				</div>
				</>
			)}
		</div>
	);
};
export default QtxToLab;
